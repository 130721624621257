import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { sendEmailVerification, User } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject, from, of, Subject, zip } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';
import { UserModel } from '../../../../shared/models/user.model';
import { AlertService } from './alert.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public user: BehaviorSubject<User> = new BehaviorSubject(null);
  public userData: BehaviorSubject<UserModel> = new BehaviorSubject(null);

  private destroyed = new Subject();

  constructor(
    private afAuth: AngularFireAuth,
    private afStore: AngularFirestore,
    private afDatabase: AngularFireDatabase,
    private userService: UserService,
    private router: Router,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    afAuth.authState
      .pipe(
        switchMap((user) => {
          this.user.next(user);
          if (user) {
            Sentry.setUser({
              id: user.uid,
              email: user.email,
            });
            this.afDatabase.database.goOnline();
          } else {
            Sentry.setUser(null);
            this.afDatabase.database.goOffline();
          }
          return zip(
            from(
              user
                ? this.afStore.firestore.enableNetwork()
                : this.afStore.firestore.disableNetwork(),
            ),
            of(user),
          );
        }),
        switchMap(([_, user]) => {
          if (user) {
            return this.userService.getUser(user.uid);
          }
          return of(null);
        }),
      )
      .subscribe((userData) => {
        this.setTheme(userData ? userData.theme : 'LIGHT');
        this.userData.next(userData);
      });
  }

  public getCurrentAuthUser(): Promise<User> {
    return this.user
      .asObservable()
      .pipe(
        skipWhile((user) => !user),
        take(1),
      )
      .toPromise();
  }

  public getCurrentUser(): Promise<UserModel> {
    return this.userData
      .asObservable()
      .pipe(
        skipWhile((userData) => !userData),
        take(1),
      )
      .toPromise();
  }

  public hasAnyRole(roles: string[]): boolean {
    return this.userData.value ? roles.includes(this.userData.value.role) : false;
  }

  public setTheme(theme: string) {
    const body = this.document.querySelectorAll('body')[0];
    if (theme === 'LIGHT') {
      body.className = 'app-light';
    } else {
      body.className = 'app-dark';
    }
  }

  public async login(email: string, password: string) {
    await this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.router.navigate(['reservations']);
      })
      .catch(() => {
        this.alertService.warn('ERRORS.LOGIN');
      });
  }

  public forgotPassword(email: string) {
    this.afAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.alertService.primary('SUCCESS.PASSWORD_RESET');
      })
      .catch(() => {
        this.alertService.warn('ERRORS.PASSWORD_RESET');
      });
  }

  public signUp(email: string, password: string) {
    this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        return this.router.navigate(['reservations']);
      })
      .then(() => {
        return sendEmailVerification(this.user.value);
      })
      .catch((error) => {
        let translateKey;
        switch (error.code) {
          case 'auth/email-already-in-use':
            translateKey = 'ERRORS.FIREBASE.EMAIL_IN_USE';
            break;
          case 'auth/invalid-email':
            translateKey = 'ERRORS.FIREBASE.INVALID_EMAIL';
            break;
          case 'auth/weak-password':
            translateKey = 'ERRORS.FIREBASE.WEAK_PASSWORD';
            break;
          default:
            translateKey = 'ERRORS.GENERIC';
            break;
        }
        this.alertService.warn(translateKey);
      });
  }

  public async logout() {
    await this.afAuth
      .signOut()
      .then(() => {
        this.router.navigate(['login']);
      })
      .catch(() => {
        this.router.navigate(['login']);
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }
}
