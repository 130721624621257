<div class="app-side-navigation">
  <div class="app-side-navigation-content">
    <ng-template appHasAnyRole="ADMIN">
      <nav *ngIf="entities?.length">
        <button>Entities</button>
        <ul>
          <ng-template ngFor let-entity [ngForOf]="entities">
            <li *appHasAnyRole="entity.roles">
              <a [routerLink]="[entity.link]" routerLinkActive="app-side-navigation-item-selected">{{ entity.name }}</a>
            </li>
          </ng-template>
        </ul>
      </nav>
      <nav *ngIf="languages?.length">
        <button>Languages</button>
        <ul>
          <li *ngFor="let language of languages">
            <a href="javascript:void(null)" (click)="changeLang(language.code)">{{ language.name }}</a>
          </li>
        </ul>
      </nav>
    </ng-template>
  </div>
</div>
