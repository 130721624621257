<mat-toolbar color="primary" [ngClass]="{ 'mat-elevation-z6': !showMode() }">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-auto d-block d-md-none">
        <button mat-icon-button (click)="sideNavigationService.toggle('left')">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="col">
        <div class="row no-gutters align-items-center">
          <div class="col-auto">
            <a class="navbar-brand">
              <img src="../../../assets/images/theater-light.svg" width="80" height="80" />
            </a>
            <span class="version">v{{ version }}</span>
          </div>
        </div>
      </div>
      <div class="col-auto d-none d-md-block">
        <!--<ng-template appHasAnyRole="ADMIN">
          <button *ngIf="languages?.length" mat-button [matMenuTriggerFor]="languageMenu">
            Language
          </button>
          <mat-menu #languageMenu="matMenu">
            <button *ngFor="let language of languages" (click)="changeLang(language.code)" mat-menu-item>
              {{ language.name }}
            </button>
          </mat-menu>
        </ng-template>-->
        <button routerLink="/reservations" routerLinkActive="router-active" mat-button>
          <mat-icon class="mr-1">event_seat</mat-icon>
          <span>Reservieren</span>
        </button>
        <ng-template appHasAnyRole="ADMIN">
          <button routerLink="/dashboard" routerLinkActive="router-active" mat-button>
            <mat-icon class="mr-1">dashboard</mat-icon>
            <span>Dashboard</span>
          </button>
          <button *ngFor="let entity of entities" [routerLink]="[entity.link]" routerLinkActive="router-active" mat-button>
            <mat-icon class="mr-1">{{ entity.icon }}</mat-icon>
            <span>{{ entity.translateKey | translate }}</span>
          </button>
        </ng-template>
      </div>
      <div class="col-auto">
        <button id="settings" routerLink="/settings" routerLinkActive="router-active" mat-icon-button>
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-toolbar>
