<mat-sidenav-container (scroll)="$event.stopPropagation()" [hasBackdrop]="hasBackdrop()" (backdropClick)="sideNavigationService.closeOpened()">
  <mat-sidenav fixedTopGap="64" fixedInViewport="true" mode="over" (closed)="sideNavigationService.closeLeft()" [opened]="sideNavigationService.leftOpen">
    <app-side-nav></app-side-nav>
  </mat-sidenav>
  <mat-sidenav
    fixedTopGap="64"
    fixedInViewport="true"
    class="stats"
    mode="side"
    position="end"
    (closed)="sideNavigationService.closeRight()"
    (opened)="sideNavigationService.rightOpened()"
    [opened]="sideNavigationService.rightOpen"
  >
    <app-stats></app-stats>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{ 'py-3': authService.user.value && !showMode() }">
    <div [ngClass]="{ 'container-fluid': authService.user.value && !showMode() }">
      <div [ngClass]="{ 'no-gutters': !authService.user.value || showMode() }" class="row">
        <div class="col">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
