export class Condition {
  private readonly examinedObject: any;
  private isOptional: boolean = false;

  public constructor(object: any) {
    this.examinedObject = object;
  }

  public optional(): Condition {
    this.isOptional = true;
    return this;
  }

  public string(name: string, withCheck?: () => boolean): boolean {
    return this.check(name, () => typeof (this.examinedObject[name]) === 'string' && (!withCheck || withCheck()));
  }

  public number(name: string, withCheck?: () => boolean): boolean {
    return this.check(name, () => typeof (this.examinedObject[name]) === 'number' && (!withCheck || withCheck()));
  }

  public boolean(name: string, withCheck?: () => boolean): boolean {
    return this.check(name, () => typeof (this.examinedObject[name]) === 'boolean' && (!withCheck || withCheck()));
  }

  public object(name: string, withCheck?: () => boolean): boolean {
    return this.check(name, () => typeof (this.examinedObject[name]) === 'object' && !(this.examinedObject[name] instanceof Array) && (!withCheck || withCheck()));
  }

  public array(name: string, withCheck?: () => boolean): boolean {
    return this.check(name, () => typeof (this.examinedObject[name]) === 'object' && (this.examinedObject[name] instanceof Array) && (!withCheck || withCheck()));
  }

  private check(name: string, checkFunction: () => boolean): boolean {
    if (this.isOptional) {
      return (!this.exists(name) || checkFunction());
    }

    return (this.exists(name) && checkFunction());
  }

  private exists(name: string): boolean {
    return this.examinedObject[name] !== undefined && this.examinedObject[name] !== null;
  }
}

export function has(object: any): Condition {
  return new Condition(object);
}
