import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HasAnyRoleDirective } from '../directives/has-any-role.directive';
import { IsOwnerDirective } from '../directives/is-owner.directive';
import { LocalizedDatePipe } from '../pipes/localized-date.pipe';
import { TranslateCutPipe } from '../pipes/translate-cut.pipe';

@NgModule({
  declarations: [
    TranslateCutPipe,
    LocalizedDatePipe,
    HasAnyRoleDirective,
    IsOwnerDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    TranslateCutPipe,
    LocalizedDatePipe,
    MatNativeDateModule,
    MatDatepickerModule,
    HasAnyRoleDirective,
    IsOwnerDirective,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de',
    },
  ],
})
export class SharedModule {}
