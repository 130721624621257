import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SideNavigationService } from '../../services/side-navigation.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public sideNavigationService: SideNavigationService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigateByUrl('/reservations');
    }
  }

  public showMode() {
    return this.router.url.includes('reservations');
  }

  hasBackdrop() {
    return !this.authService.hasAnyRole(['ADMIN']);
  }
}
