import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appHasAnyRole]',
})
export class HasAnyRoleDirective {
  private roles: string[] = [];

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.authService.userData.subscribe(() => this.updateView());
  }

  @Input()
  set appHasAnyRole(value: string | string[]) {
    this.roles = typeof value === 'string' ? [value as string] : (value as string[]);
    this.updateView();
  }

  private updateView(): void {
    this.viewContainerRef.clear();
    if (this.authService.hasAnyRole(this.roles)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
