import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { delay, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { AlertComponent } from '../components/alert/alert.component';

export interface SnackBarQueueItem {
  beingDispatched: boolean;
  configParams?: MatSnackBarConfig;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService implements OnDestroy {
  public online = true;
  private readonly snackBarQueue = new BehaviorSubject<SnackBarQueueItem[]>([]);
  private readonly snackBarQueue$ = this.snackBarQueue.asObservable();
  private readonly ngDestroy = new Subject();
  private readonly DURATION = 5000;

  constructor(private snackBar: MatSnackBar, private router: Router) {
    this.snackBarQueue$
      .pipe(
        filter((queue) => queue.length > 0 && !queue[0].beingDispatched),
        tap(() => {
          const updatedQueue = this.snackBarQueue.value;
          updatedQueue[0].beingDispatched = true;
          this.snackBarQueue.next(updatedQueue);
        }),
        map((queue) => queue[0]),
        takeUntil(this.ngDestroy),
      )
      .subscribe((snackBarItem) => {
        this.show(snackBarItem.configParams);
      });
    fromEvent(window, 'online')
      .pipe(takeUntil(this.ngDestroy))
      .subscribe(() => {
        this.online = true;
      });
    fromEvent(window, 'offline')
      .pipe(takeUntil(this.ngDestroy))
      .subscribe(() => {
        this.online = false;
      });
  }

  public primary(translateKey: string, duration = this.DURATION) {
    this.queue({
      duration,
      data: {
        translateKey,
        duration,
        color: 'primary',
      },
    });
  }

  public warn(translateKey: string, duration = this.DURATION) {
    this.queue({
      duration,
      data: {
        translateKey,
        duration,
        color: 'warn',
      },
    });
  }

  public accent(translateKey: string, duration = this.DURATION) {
    this.queue({
      duration,
      data: {
        translateKey,
        duration,
        color: 'accent',
      },
    });
  }

  private queue(configParams?: MatSnackBarConfig) {
    this.snackBarQueue.next(
      this.snackBarQueue.value.concat([{ configParams, beingDispatched: false }]),
    );
  }

  private show(configParams?: MatSnackBarConfig) {
    this.removeDismissedSnackBar(
      this.snackBar.openFromComponent(AlertComponent, configParams).afterDismissed(),
    );
  }

  private removeDismissedSnackBar(dismissed: Observable<MatSnackBarDismiss>) {
    dismissed.pipe(delay(200), take(1)).subscribe(() => {
      const updatedQueue = this.snackBarQueue.value;
      if (updatedQueue[0].beingDispatched) updatedQueue.shift();
      this.snackBarQueue.next(updatedQueue);
    });
  }

  ngOnDestroy(): void {
    this.snackBarQueue.next([]);
    this.snackBarQueue.complete();
    this.ngDestroy.next(undefined);
    this.ngDestroy.complete();
  }
}
