import { Injectable, OnDestroy } from '@angular/core';
import {
  AngularFirestore,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { OrderByDirection, serverTimestamp, WhereFilterOp } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { UserModel } from '../../../../shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private destroyed = new Subject();

  constructor(private firestore: AngularFirestore, private firebaseStorage: AngularFireStorage) {}

  public inviteUser(user: UserModel): Promise<void> {
    user.createdDate = serverTimestamp();
    user.updatedDate = serverTimestamp();
    return this.firestore.collection<UserModel>('invites').doc(user.email).set(user);
  }

  public updateEmail(id: string, email: string): Promise<void> {
    return this.firestore.collection('users').doc(id).update({ email });
  }

  public async updateUser(user: UserModel, progress: Function): Promise<void> {
    if (user.avatar.files && user.avatar.files.length) {
      const avatar: File = user.avatar.files[0];
      const type = avatar.type.split('image/')[1];
      const path = `users/${user.id}/avatar.${type}`;
      const upload = await this.firebaseStorage.upload(path, avatar);
      user.avatar = await upload.ref.getDownloadURL();
      progress(50);
    }
    user.updatedDate = serverTimestamp();
    return this.firestore.collection<UserModel>('users').doc(user.id).set(user);
  }

  public deleteUser(user: UserModel): Promise<void> {
    return this.firestore.collection<UserModel>('users').doc(user.id).delete();
  }

  public getUser(id: string): Observable<UserModel> {
    return this.firestore
      .collection<UserModel>('users')
      .doc<UserModel>(id)
      .valueChanges()
      .pipe(takeUntil(this.destroyed));
  }

  public getAll(
    field: string = 'createdDate',
    orderBy: OrderByDirection = 'asc',
    limit: number = 10,
    startAfter?: QueryDocumentSnapshot<UserModel>,
    searchValue?: any,
    opStr?: WhereFilterOp,
  ): Observable<QueryDocumentSnapshot<UserModel>[]> {
    return this.firestore
      .collection<UserModel>('users', (ref) => {
        let query: Query;
        if (['boolean', 'number'].includes(typeof searchValue) || searchValue) {
          query = ref.where(field, opStr, searchValue);
        } else {
          query = ref.limit(limit);
          query = query.orderBy(field, orderBy);
          if (startAfter) {
            query = query.startAfter(startAfter);
          }
        }
        return query;
      })
      .get()
      .pipe(map((res: QuerySnapshot<UserModel>) => res.docs));
  }

  public searchPublicUser(email: string): Observable<UserModel[]> {
    return this.firestore
      .collection<UserModel>('publicUsers', (ref) => ref.where('email', '==', email))
      .valueChanges()
      .pipe(take(1));
  }

  ngOnDestroy() {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }
}
