export const environment = {
  production: true,
  environment: 'live',
  firebase: {
    apiKey: 'AIzaSyCVZaciGaqzLKZ3r_a2UYjuBtpQF59g0HE',
    authDomain: 'theater-eberhardzell.firebaseapp.com',
    databaseURL: 'https://theater-eberhardzell.firebaseio.com',
    projectId: 'theater-eberhardzell',
    storageBucket: 'theater-eberhardzell.appspot.com',
    messagingSenderId: '24506237295',
    appId: '1:24506237295:web:0e2b2ed8bfa92a50',
  },
};
