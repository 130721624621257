import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe extends DatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
    super(translateService.currentLang);
  }

  transform(value: any, pattern = 'medium'): any {
    return super.transform(value, pattern, undefined, this.translateService.currentLang);
  }
}
