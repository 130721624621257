import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ShowModel } from '../../../../../../shared/models/show.model';
import { ChatService } from '../../../services/chat.service';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
})
export class ChatInputComponent implements OnInit, OnChanges {
  @Input() public show: ShowModel;
  public sendMessageForm: UntypedFormGroup;
  public filterEnabled = false;

  constructor(public chatService: ChatService) {}

  ngOnInit() {
    this.sendMessageForm = new UntypedFormGroup({
      content: new UntypedFormControl({ value: '', disabled: false }),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const before: ShowModel = changes.show.previousValue;
    const after: ShowModel = changes.show.currentValue;
    if (!before || !after || before.id === after.id) {
      return;
    }
    if (this.filterEnabled) {
      this.chatService.filterByShow(after.id);
    }
  }

  onKeyUp(event) {
    if (event.code === 'Enter' && !event.shiftKey) {
      this.sendMessage();
    }
  }

  checkboxChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.sendMessageForm.controls.content.disable();
      this.filterEnabled = true;
      this.chatService.filterByShow(this.show.id);
    } else {
      this.sendMessageForm.controls.content.enable();
      this.filterEnabled = false;
      this.chatService.loadMessages(true);
    }
  }

  sendMessage() {
    const content = this.sendMessageForm.controls.content.value.trim();
    if (!content) {
      return;
    }
    this.chatService.sendMessage(content);
    this.sendMessageForm.patchValue({
      content: '',
    });
  }
}
