export const ENTITIES = [
  {
    name: 'Show',
    icon: 'theaters',
    link: '/show',
    translateKey: 'ENTITIES.SHOW.TITLE',
    roles: ['ADMIN'],
  },
  {
    name: 'User',
    icon: 'supervised_user_circle',
    link: '/user',
    translateKey: 'ENTITIES.USER.TITLE',
    roles: ['ADMIN'],
  },
]; // entity-hook

export const LANGUAGES = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'German',
    code: 'de',
  },
];

export const ROLES = [
  {
    name: 'ADMIN',
    disabled: false,
  },
  {
    name: 'USER',
    disabled: false,
  },
];

export const PAGINATION = {
  pageSizeOptions: [5, 10, 20],
  pageSize: 20,
};
