<div class="container-fluid chat-notification">
  <div class="row align-items-center">
    <div class="col">
      <div class="row">
        <div class="col-auto author">{{ data.author }}</div>
        <div class="col-12">
          {{ data.content.length > 100 ? (data.content | slice: 0:100) + '…' : data.content }}
        </div>
      </div>
    </div>
    <div class="col-auto">
      <button mat-button (click)="onOpenClicked()">{{ 'COMMON.OPEN' | translate }}</button>
      <button mat-button (click)="onDismissClicked()">{{ 'COMMON.DISMISS' | translate }}</button>
    </div>
  </div>
</div>
