<mat-tab-group mat-align-tabs="center" [selectedIndex]="sideNavigationService.selectedIndex.value" (selectedIndexChange)="selectedIndexChange($event)">
  <mat-tab>
    <ng-template matTabLabel>
      <span [matBadgeHidden]="!chatService.unreadCount" [matBadge]="chatService.unreadCount" matBadgeOverlap="false" matBadgeColor="warn">{{ 'SHOW_OVERVIEW.CHAT.TITLE' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-chat [show]="currentShow"></app-chat>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="currentShow" label="{{ 'SHOW_OVERVIEW.STATS.SHOW' | translate }}">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 my-3">
          <div class="row align-items-center">
            <div class="col-12">{{ 'SHOW_OVERVIEW.STATS.OCCUPANCY_RATE' | translate }}</div>
            <div class="col">
              <mat-progress-bar color="accent" mode="determinate" [value]="(currentShow.occupiedSeatAmount / currentShow.availableSeatAmount) * 100"></mat-progress-bar>
            </div>
            <div class="col-auto">{{ currentShow.occupiedSeatAmount / currentShow.availableSeatAmount | percent }}</div>
          </div>
          <div class="row align-items-center">
            <div class="col-12">{{ 'ENTITIES.SHOW.PAID' | translate }}</div>
            <div class="col">
              <mat-progress-bar color="primary" mode="determinate" [value]="(currentShow.paidSeatAmount / (currentShow.occupiedSeatAmount || 1)) * 100"></mat-progress-bar>
            </div>
            <div class="col-auto">{{ currentShow.paidSeatAmount / (currentShow.occupiedSeatAmount || 1) | percent }}</div>
          </div>
        </div>
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
          <input matInput placeholder="{{ 'COMMON.SEARCH' | translate }}" [formControl]="currentShowSearchCtrl" />
        </mat-form-field>
        <div class="col-12 mb-3">
          <div class="table-responsive mat-elevation-z2">
            <table mat-table matSort #currentShowSort="matSort" matSortDirection="asc" matSortActive="name" [dataSource]="currentShowDataSource" class="w-100">
              <ng-container matColumnDef="name">
                <th mat-sort-header="name" mat-header-cell *matHeaderCellDef>&nbsp;{{ 'ENTITIES.USER.NAME' | translate }}&nbsp;</th>
                <td mat-cell *matCellDef="let element">&nbsp;{{ element.name }}&nbsp;</td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-sort-header="amount" mat-header-cell *matHeaderCellDef>&nbsp;{{ 'COMMON.AMOUNT' | translate }}&nbsp;</th>
                <td mat-cell *matCellDef="let element">&nbsp;{{ element.amount }}&nbsp;</td>
              </ng-container>

              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>&nbsp;{{ 'ENTITIES.SHOW.COSTS' | translate }}&nbsp;</th>
                <td mat-cell *matCellDef="let element">&nbsp;{{ element.amount * currentShow?.costs || 0 | currency : 'EUR' : 'symbol' : '1.2-2' : 'de' }}&nbsp;</td>
              </ng-container>

              <ng-container matColumnDef="paidAmount">
                <th mat-sort-header="paidAmount" mat-header-cell *matHeaderCellDef>&nbsp;{{ 'ENTITIES.SHOW.PAID' | translate }}&nbsp;</th>
                <td mat-cell *matCellDef="let element">&nbsp;{{ element.paidAmount }}&nbsp;</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>&nbsp;&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                  <button *ngIf="element.paidAmount !== element.amount" mat-icon-button (click)="$event.stopPropagation(); markAsPaid(element)">
                    <mat-icon>attach_money</mat-icon>
                  </button>
                  <button *ngIf="element.paidAmount === element.amount" mat-icon-button (click)="$event.stopPropagation(); markAsNotPaid(element)">
                    <mat-icon>money_off</mat-icon>
                  </button>
                  <button *ngIf="!element.paidAmount" mat-icon-button (click)="$event.stopPropagation(); cancelAll(element)">
                    <mat-icon>cancel_presentation</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="currentShowDisplayedColumns"></tr>
              <tr
                [class.paid]="row.paidAmount === row.amount"
                [class.partly-paid]="row.paidAmount > 0"
                [class.not-paid]="row.paidAmount === 0"
                mat-row
                *matRowDef="let row; columns: currentShowDisplayedColumns"
                (click)="selectVisitor(row.id)"
              ></tr>
            </table>

            <mat-paginator #currentShowPaginator pageSize="5" [pageSizeOptions]="paginationConfig.pageSizeOptions"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab *ngIf="allShows" label="{{ 'SHOW_OVERVIEW.STATS.ALL' | translate }}">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 my-3">
          <div class="row align-items-center">
            <div class="col-12">{{ 'SHOW_OVERVIEW.STATS.OCCUPANCY_RATE' | translate }}</div>
            <div class="col">
              <mat-progress-bar color="accent" mode="determinate" [value]="getPercentage() * 100"></mat-progress-bar>
            </div>
            <div class="col-auto">{{ getPercentage() | percent }}</div>
          </div>
        </div>
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
          <input matInput placeholder="{{ 'COMMON.SEARCH' | translate }}" [formControl]="allShowsSearchCtrl" />
        </mat-form-field>
        <div class="col-12 mb-3">
          <div class="table-responsive mat-elevation-z2">
            <table mat-table matSort #allShowsSort="matSort" matSortDirection="asc" matSortActive="name" [dataSource]="allShowsDataSource" class="w-100">
              <ng-container matColumnDef="name">
                <th mat-sort-header="name" mat-header-cell *matHeaderCellDef>&nbsp;{{ 'ENTITIES.USER.NAME' | translate }}&nbsp;</th>
                <td mat-cell *matCellDef="let element">&nbsp;{{ element.name }}&nbsp;</td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-sort-header="email" mat-header-cell *matHeaderCellDef>&nbsp;{{ 'ENTITIES.USER.EMAIL' | translate }}&nbsp;</th>
                <td mat-cell *matCellDef="let element">&nbsp;{{ element.email || '-' }}&nbsp;</td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-sort-header="amount" mat-header-cell *matHeaderCellDef>&nbsp;{{ 'COMMON.AMOUNT' | translate }}&nbsp;</th>
                <td mat-cell *matCellDef="let element">&nbsp;{{ element.amount }}&nbsp;</td>
              </ng-container>

              <ng-container matColumnDef="show">
                <th mat-sort-header="show" mat-header-cell *matHeaderCellDef>&nbsp;{{ 'ENTITIES.SHOW.TITLE' | translate }}&nbsp;</th>
                <td mat-cell *matCellDef="let element">&nbsp;{{ element.show.date.seconds * 1000 | localizedDate : 'short' }}&nbsp;</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="allShowsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: allShowsDisplayedColumns" (click)="selectVisitor(row.id, row.show)"></tr>
            </table>

            <mat-paginator #allShowsPaginator pageSize="5" [pageSizeOptions]="paginationConfig.pageSizeOptions"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
