import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageModel } from '../../../../../../shared/models/message.model';
import { ChatService } from '../../../services/chat.service';

@Component({
  selector: 'app-chat-content',
  templateUrl: './chat-content.component.html',
  styleUrls: ['./chat-content.component.scss'],
})
export class ChatContentComponent implements OnInit, OnDestroy {
  messages: MessageModel[] = [];

  private destroyed = new Subject();

  constructor(public chatService: ChatService) {}

  ngOnInit() {
    this.chatService.messagesObservable
      .asObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe((messages) => {
        this.messages = messages;
      });
  }

  hasSameAuthor(index: number, message: MessageModel) {
    if (index < 0 || index >= this.messages.length) {
      return false;
    }
    return message.authorId === this.messages[index].authorId;
  }

  ngOnDestroy(): void {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }
}
