import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ShowModel } from '../../../../shared/models/show.model';
import { ShowService } from '../services/show.service';

@Injectable({ providedIn: 'root' })
export class ShowResolver implements Resolve<ShowModel> {
  constructor(private showService: ShowService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return this.showService.getShow(route.params.showId).pipe(
      take(1),
      catchError(() => of(null)),
    );
  }
}
