import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ENTITIES, LANGUAGES } from '../../shared/config/config';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  entities = ENTITIES;
  languages = LANGUAGES;

  constructor(private translate: TranslateService, private router: Router) {}

  ngOnInit() {}

  public changeLang(lang: string): void {
    this.translate.use(lang);
  }
}
