import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideNavigationService {
  public leftOpen = false;
  public rightOpen = false;

  public selectedIndex = new BehaviorSubject(1);

  public data = new BehaviorSubject(null);
  public highlightId = new BehaviorSubject(null);
  public printView = new BehaviorSubject(false);
  public reload = new Subject<boolean>();

  constructor() {}

  toggle(which: 'left' | 'right', index?: number): void {
    if (which === 'left') {
      this.leftOpen = !this.leftOpen;
    } else {
      this.reload.next(true);
      this.rightOpen = !this.rightOpen;
      if (this.rightOpen && (this.selectedIndex.value === 0 || index === 0)) {
        this.selectedIndex.next(0);
      }
    }
  }

  rightOpened(): void {
    this.reload.next(false);
  }

  closeLeft(): void {
    this.leftOpen = false;
  }

  closeRight(): void {
    this.rightOpen = false;
    this.reload.next(false);
  }

  closeOpened(): void {
    if (this.leftOpen) {
      this.leftOpen = false;
    }
    if (this.rightOpen) {
      this.rightOpen = false;
      this.reload.next(true);
    }
  }
}
