import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { ShowModel } from '../../../../shared/models/show.model';
import { ShowService } from '../services/show.service';

@Injectable({ providedIn: 'root' })
export class ReservationsResolver implements Resolve<ShowModel> {
  constructor(private showService: ShowService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return this.showService.shows.asObservable().pipe(
      skipWhile((shows) => shows.length <= 0),
      take(1),
    );
  }
}
