import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { version } from '../../../../package.json';
import { AlertService } from '../../services/alert.service';
import { ChatService } from '../../services/chat.service';
import { SideNavigationService } from '../../services/side-navigation.service';
import { ENTITIES, LANGUAGES } from '../../shared/config/config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public entities = ENTITIES;
  public languages = LANGUAGES;
  public version: string = '1.60.1';

  constructor(
    public sideNavigationService: SideNavigationService,
    public alertService: AlertService,
    public chatService: ChatService,
    private translate: TranslateService,
    private router: Router,
  ) {}

  ngOnInit() {}

  public showMode() {
    return this.router.url.includes('reservations');
  }

  public changeLang(lang: string): void {
    this.translate.use(lang);
  }
}
