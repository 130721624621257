import { Component, Input, OnInit } from '@angular/core';
import { ShowModel } from '../../../../../../shared/models/show.model';
import { ChatService } from '../../../services/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  @Input() public show: ShowModel;

  constructor(public chatService: ChatService) {}

  ngOnInit() {}

  loadMore() {
    this.chatService.loadMoreMessages();
  }
}
