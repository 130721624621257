import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  AngularFireAuthGuardModule,
  customClaims,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContainerComponent } from './layout/container/container.component';
import { ReservationsResolver } from './resolvers/reservations.resolver';
import { ShowResolver } from './resolvers/show.resolver';
import { UserResolver } from './resolvers/user.resolver';
// import-hook

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToReservations = () => redirectLoggedInTo(['reservations']);
const isAdmin = () =>
  pipe(
    customClaims,
    map((claims) => claims.role === 'ADMIN'),
    map((admin) => (admin ? true : redirectLoggedInToReservations)),
  );
const isAdminOrUser = () =>
  pipe(
    customClaims,
    map((claims) => claims.role === 'ADMIN' || claims.role === 'USER'),
    map((hasRole) => (hasRole ? true : redirectUnauthorizedToLogin)),
  );

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/user-login-page/user-login-page.module').then(
        (mod) => mod.UserLoginPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToReservations },
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/user-forgot-password-page/user-forgot-password-page.module').then(
        (mod) => mod.UserForgotPasswordPageModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToReservations },
  },
  {
    path: 'legal',
    component: ContainerComponent,
    children: [
      {
        path: 'data-privacy',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/data-privacy-page/data-privacy-page.module').then(
            (mod) => mod.DataPrivacyPageModule,
          ),
      },
      {
        path: 'imprint',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/imprint-page/imprint-page.module').then((mod) => mod.ImprintPageModule),
      },
    ],
  },
  {
    path: '',
    component: ContainerComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: 'settings',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/settings-page/settings-page.module').then(
            (mod) => mod.SettingsPageModule,
          ),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: isAdminOrUser },
      },
      {
        path: 'reservations',
        loadChildren: () =>
          import('./pages/reservations-page/reservations-page.module').then(
            (mod) => mod.ReservationsPageModule,
          ),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: isAdminOrUser },
        resolve: {
          shows: ReservationsResolver,
        },
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard-page/dashboard-page.module').then(
            (mod) => mod.DashboardPageModule,
          ),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: isAdmin },
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            pathMatch: 'full',
            loadChildren: () =>
              import('./entities/user/list-user-page/list-user-page.module').then(
                (mod) => mod.ListUserPageModule,
              ),
          },
          {
            path: 'create',
            pathMatch: 'full',
            loadChildren: () =>
              import('./entities/user/create-user-page/create-user-page.module').then(
                (mod) => mod.CreateUserPageModule,
              ),
          },
          {
            path: ':id',
            pathMatch: 'full',
            loadChildren: () =>
              import('./entities/user/detail-user-page/detail-user-page.module').then(
                (mod) => mod.DetailUserPageModule,
              ),
            resolve: {
              user: UserResolver,
            },
          },
          {
            path: ':id/edit',
            pathMatch: 'full',
            loadChildren: () =>
              import('./entities/user/create-user-page/create-user-page.module').then(
                (mod) => mod.CreateUserPageModule,
              ),
            resolve: {
              user: UserResolver,
            },
          },
        ],
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: isAdmin },
      },
      {
        path: 'show',
        children: [
          {
            path: '',
            pathMatch: 'full',
            loadChildren: () =>
              import('./entities/show/list-show-page/list-show-page.module').then(
                (mod) => mod.ListShowPageModule,
              ),
          },
          {
            path: 'create',
            pathMatch: 'full',
            loadChildren: () =>
              import('./entities/show/create-show-page/create-show-page.module').then(
                (mod) => mod.CreateShowPageModule,
              ),
          },
          {
            path: ':showId',
            loadChildren: () =>
              import('./entities/show/detail-show-page/detail-show-page.module').then(
                (mod) => mod.DetailShowPageModule,
              ),
          },
          {
            path: ':showId/edit',
            pathMatch: 'full',
            loadChildren: () =>
              import('./entities/show/create-show-page/create-show-page.module').then(
                (mod) => mod.CreateShowPageModule,
              ),
            resolve: {
              show: ShowResolver,
            },
          },
        ],
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: isAdmin },
      },
    ], // routing-hook
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    AngularFireAuthGuardModule,
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
