import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  progress = 0;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackbarRef: MatSnackBarRef<AlertComponent>,
  ) {}

  ngOnInit() {
    if (this.data.duration) {
      timer(0, this.data.duration / 100)
        .pipe(take(100))
        .subscribe((progress) => (this.progress = progress));
    }
  }
}
