import { has } from "../conditions";
import { isStateModel, StateModel } from "./state.model";

export interface SeatModel {
  id: string;
  owner: string;
  createdDate: any;
  updatedDate: any;
  showId: string;
  number: number;
  position: number;
  alias?: string;
  row: number;
  table: number;
  state: StateModel;
  // make sure this field is only set locally!
  tooltip?: string;
  group?: {
    "font-size": string;
    "min-height": string;
    width: string;
    left: string;
    right: string;
  };
  groupAmount?: number;
}

export function isSeatModel(object: any): object is SeatModel {
  return (
    object &&
    has(object).string("id") &&
    has(object).string("owner") &&
    has(object).object("createdDate") &&
    has(object).object("updatedDate") &&
    has(object).string("showId") &&
    has(object).number("number") &&
    has(object).number("position") &&
    has(object).optional().string("alias") &&
    has(object).number("row") &&
    has(object).number("table") &&
    has(object).object("state", () => isStateModel(object.state))
  );
}

export function removeLocalSeatFields(seat: SeatModel): void {
  delete seat.tooltip;
  delete seat.group;
  delete seat.groupAmount;
  if (seat && seat.state && seat.state.info && seat.state.info.visitor) {
    delete seat.state.info.visitor.color;
    delete seat.state.info.visitor.backgroundColor;
    delete seat.state.info.visitor.initials;
  }
}
