<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<mat-dialog-content>
  <div>{{ data.description | translate }}</div>
  <div *ngIf="data.approveVariable">
    <div class="my-3" [innerHTML]="'COMMON.APPROVE_TEXT' | translate: { approve: data.approveVariable }"></div>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ 'ENTITIES.USER.NAME' | translate }}</mat-label>
      <input type="text" [(ngModel)]="approveInput" required matInput placeholder="{{ 'ENTITIES.USER.NAME' | translate }}" />
    </mat-form-field>
    <mat-slide-toggle [(ngModel)]="toggle" *ngIf="data.toggle" class="w-100">
      <span>{{ 'ENTITIES.USER.EMAIL_BOOKING_CHANGED' | translate }}</span>
    </mat-slide-toggle>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>
    {{ data.cancel || 'COMMON.CANCEL' | translate }}
  </button>
  <button mat-button color="warn" [mat-dialog-close]="data.toggle ? { toggle: toggle } : true" [disabled]="data.approveVariable && approveInput.trim() !== data.approveVariable">
    {{ data.close || 'COMMON.DELETE' | translate }}
  </button>
</mat-dialog-actions>
