import { Injectable, OnDestroy } from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { OrderByDirection, serverTimestamp, WhereFilterOp } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { map, skipWhile, take, takeUntil } from 'rxjs/operators';
import {
  isSeatModel,
  removeLocalSeatFields,
  SeatModel,
} from '../../../../shared/models/seat.model';
import { ShowModel } from '../../../../shared/models/show.model';
import { SeatType } from '../../../../shared/types/seat.type';
import { AuthService } from './auth.service';
import { ChatService } from './chat.service';

@Injectable({
  providedIn: 'root',
})
export class SeatService implements OnDestroy {
  private destroyed = new Subject();

  constructor(
    private firestore: AngularFirestore,
    private firebaseStorage: AngularFireStorage,
    private authService: AuthService,
    private chatService: ChatService,
  ) {}

  public async createSeat(parentId: string, seat: SeatModel, progress?: Function): Promise<void> {
    const user = await this.authService.getCurrentAuthUser();
    removeLocalSeatFields(seat);
    seat.id = this.firestore.createId();
    seat.showId = parentId;
    seat.createdDate = serverTimestamp();
    seat.updatedDate = serverTimestamp();
    seat.owner = user.uid;
    if (!seat.state) {
      seat.state = {
        value: 'FREE',
        info: null,
      };
    }
    if (!isSeatModel(seat)) {
      return Promise.reject();
    }
    return this.firestore
      .collection('shows')
      .doc(parentId)
      .collection<SeatModel>('seats')
      .doc(seat.id)
      .set(seat);
  }

  public async updateSeat(show: ShowModel, seat: SeatModel, progress?: Function): Promise<void> {
    // check if we have a transition from OCCUPIED to FREE. If yes, send a message
    if (seat.state.info && seat.state.info.visitor) {
      if (seat.state.value === 'FREE') {
        await this.chatService.sendUnReserveMessage(show, [seat]);
        seat.state.info = null;
      }
    }
    removeLocalSeatFields(seat);
    seat.updatedDate = serverTimestamp();
    if (!isSeatModel(seat)) {
      return Promise.reject();
    }
    return this.firestore
      .collection('shows')
      .doc(show.id)
      .collection<SeatModel>('seats')
      .doc(seat.id)
      .set(seat);
  }

  public async updateSeats(show: ShowModel, seats: SeatModel[], toState?: SeatType): Promise<void> {
    if (!show || !seats || !seats.length) {
      return;
    }
    const fromState = seats[0].state.value;
    // check if we have a transition from FREE to OCCUPIED. If yes, send a message
    if (fromState === 'ASSIGNING' && toState === 'OCCUPIED') {
      await this.chatService.sendReservedMessage(show, seats);
      seats.forEach((seat) => {
        seat.state.value = toState;
      });
    } else if (fromState === 'OCCUPIED' && toState === 'FREE') {
      await this.chatService.sendUnReserveMessage(show, seats);
      seats.forEach((seat) => {
        seat.state.info = null;
        seat.state.value = toState;
      });
    } else if (fromState === 'ASSIGNING' && toState === 'FREE') {
      seats.forEach((seat) => {
        seat.state.info = null;
        seat.state.value = toState;
      });
    } else if (toState) {
      seats.forEach((seat) => {
        seat.state.value = toState;
      });
    }
    const batch = this.firestore.firestore.batch();
    for (const seat of seats) {
      removeLocalSeatFields(seat);
      seat.updatedDate = serverTimestamp();
      if (!isSeatModel(seat)) {
        continue;
      }
      const ref = this.firestore.firestore
        .collection('shows')
        .doc(show.id)
        .collection('seats')
        .doc(seat.id);
      batch.update(ref, seat);
    }
    return batch.commit();
  }

  public deleteSeat(parentId: string, seat: SeatModel): Promise<void> {
    return this.firestore
      .collection('shows')
      .doc(parentId)
      .collection<SeatModel>('seats')
      .doc(seat.id)
      .delete();
  }

  public getSeat(parentId: string, id: string): Observable<SeatModel> {
    return this.firestore
      .collection('shows')
      .doc(parentId)
      .collection<SeatModel>('seats')
      .doc<SeatModel>(id)
      .valueChanges()
      .pipe(takeUntil(this.destroyed));
  }

  public getAllPaged(
    parentId: string,
    field: string = 'createdDate',
    orderBy: OrderByDirection = 'asc',
    limit: number = 10,
    startAfter?: QueryDocumentSnapshot<SeatModel>,
    searchValue?: any,
    opStr?: WhereFilterOp,
  ): Observable<QueryDocumentSnapshot<SeatModel>[]> {
    return this.firestore
      .collection('shows')
      .doc(parentId)
      .collection<SeatModel>('seats', (ref) => {
        let query: Query;
        if (['boolean', 'number'].includes(typeof searchValue) || searchValue) {
          query = ref.where(field, opStr, searchValue);
        } else {
          query = ref.limit(limit);
          query = query.orderBy(field, orderBy);
          if (startAfter) {
            query = query.startAfter(startAfter);
          }
        }
        return query;
      })
      .get()
      .pipe(map((res: QuerySnapshot<SeatModel>) => res.docs));
  }

  public getInitial(parentId: string): Observable<SeatModel[]> {
    return this.firestore
      .collection('shows')
      .doc(parentId)
      .collection<SeatModel>('seats')
      .valueChanges()
      .pipe(
        skipWhile((seats) => !seats.length),
        take(1),
      );
  }

  public getChanges(parentId: string): Observable<DocumentChangeAction<SeatModel>[]> {
    return this.firestore
      .collection('shows')
      .doc(parentId)
      .collection<SeatModel>('seats')
      .stateChanges(['modified'])
      .pipe(takeUntil(this.destroyed));
  }

  ngOnDestroy() {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }
}
