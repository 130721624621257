import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-old-plan',
  templateUrl: './old-plan.component.html',
  styleUrls: ['./old-plan.component.scss'],
})
export class OldPlanComponent implements OnInit {
  expanded = false;

  constructor() {}

  ngOnInit() {}
}
