import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[appAutoScroll]',
})
export class AutoScrollDirective implements AfterContentInit, OnDestroy {
  @Input() public lockYOffset = 20;

  private locked = false;
  private mutationObserver: MutationObserver;

  constructor(private element: ElementRef) {}

  public ngAfterContentInit(): void {
    this.mutationObserver = new MutationObserver(() => {
      if (!this.locked) {
        this.scrollDown();
      }
    });
    this.mutationObserver.observe(this.element.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  public ngOnDestroy(): void {
    this.mutationObserver.disconnect();
  }

  private scrollDown(): void {
    this.element.nativeElement.scrollTop = this.element.nativeElement.scrollHeight;
  }

  @HostListener('scroll')
  private scrollHandler(): void {
    const scrollHeight = this.element.nativeElement.scrollHeight;
    const scrollTop = this.element.nativeElement.scrollTop;
    const clientHeight = this.element.nativeElement.clientHeight;
    const scrollFromBottom = scrollHeight - scrollTop - clientHeight;
    this.locked = scrollFromBottom > this.lockYOffset;
  }
}
