<div class="container-fluid">
  <div class="row justify-content-center align-items-center">
    <div class="col-auto">
      <mat-icon *ngIf="data.color === 'primary'" [color]="data.color">check_circle</mat-icon>
      <mat-icon *ngIf="data.color === 'accent'" [color]="data.color">info</mat-icon>
      <mat-icon *ngIf="data.color === 'warn'" [color]="data.color">error</mat-icon>
    </div>
    <div class="col">{{ data.translateKey | translate }}</div>
    <div class="col-auto">
      <button [color]="data.color" mat-button (click)="snackbarRef.dismiss()">Close</button>
    </div>
  </div>
</div>
<mat-progress-bar *ngIf="data.duration" [color]="data.color" [value]="progress" mode="determinate"></mat-progress-bar>
