import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SideNavigationService } from '../../../services/side-navigation.service';

@Component({
  selector: 'app-chat-notification',
  templateUrl: './chat-notification.component.html',
  styleUrls: ['./chat-notification.component.scss'],
})
export class ChatNotificationComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackbarRef: MatSnackBarRef<ChatNotificationComponent>,
    private sideNavigationService: SideNavigationService,
  ) {}

  ngOnInit() {}

  onOpenClicked() {
    this.sideNavigationService.toggle('right', 2);
    this.snackbarRef.dismiss();
  }

  onDismissClicked() {
    this.snackbarRef.dismiss();
  }
}
