import { has } from '../conditions';

export interface StateInfoModel {
  assigner?: AssignerModel;
  visitor?: VisitorModel;
  reason?: string;
}

export interface VisitorModel {
  id: string;
  name: string;
  email?: string;
  subscribeNewsletter?: boolean;
  sendMail?: boolean;
  phoneNumber?: string;
  street?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  note?: string;
  amount?: number;
  // make sure these fields are only set locally!
  color?: string;
  backgroundColor?: string;
  initials?: string;
}

export interface AssignerModel {
  id: string;
  name: string;
  color: string;
  backgroundColor: string;
}

export function isStateInfoModel(object: any): object is StateInfoModel {
  return object &&
    has(object).optional().object('assigner', () => isAssignerModel(object.assigner)) &&
    has(object).optional().object('visitor', () => isVisitorModel(object.visitor)) &&
    has(object).optional().string('reason');
}

export function isVisitorModel(object: any): object is VisitorModel {
  return object &&
    has(object).string('id') &&
    has(object).string('name') &&
    has(object).optional().string('email') &&
    has(object).optional().boolean('subscribeNewsletter') &&
    has(object).optional().boolean('sendMail') &&
    has(object).optional().string('phoneNumber') &&
    has(object).optional().string('street') &&
    has(object).optional().string('zipCode') &&
    has(object).optional().string('city') &&
    has(object).optional().string('country') &&
    has(object).optional().string('note') &&
    has(object).optional().number('amount') &&
    !object.color &&
    !object.backgroundColor &&
    !object.initials;
}

export function isAssignerModel(object: any): object is AssignerModel {
  return object &&
    has(object).string('id') &&
    has(object).string('name') &&
    has(object).string('color') &&
    has(object).string('backgroundColor');
}
