import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { serverTimestamp } from '@angular/fire/database';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isMessageModel, MessageModel } from '../../../../shared/models/message.model';
import { SeatModel } from '../../../../shared/models/seat.model';
import { ShowModel } from '../../../../shared/models/show.model';
import { ChatNotificationComponent } from '../components/chat/chat-notification/chat-notification.component'; // tslint:disable-line
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { SideNavigationService } from './side-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService implements OnDestroy {
  public messagesObservable = new BehaviorSubject<MessageModel[]>([]);
  public loading = false;
  public unreadCount = 0;

  private query: any;
  private queryPointer: string;

  private clearSubscriptions = new Subject();
  private destroyed = new Subject();

  constructor(
    private authService: AuthService,
    private configService: ConfigService,
    private database: AngularFireDatabase,
    private snackBar: MatSnackBar,
    private sideNavigationService: SideNavigationService,
  ) {
    this.authService.user
      .asObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe((user) => {
        if (user) {
          this.loadMessages();
        } else {
          this.clearSubscriptions.next(undefined);
          this.messagesObservable.next([]);
        }
      });
    this.sideNavigationService.selectedIndex
      .asObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe((selectedIndex) => {
        if (selectedIndex === 0) {
          this.unreadCount = 0;
        }
      });
  }

  public loadMessages(showSpinner = false): void {
    if (showSpinner) {
      this.loading = true;
    }
    this.clearQuery();
    this.query = this.database
      .list<MessageModel>('chat/messages')
      .query.orderByKey()
      .limitToLast(30);
    this.query.on('child_added', async (snap, queryPointer) => {
      if (!this.queryPointer) {
        this.queryPointer = queryPointer;
      }
      const message: MessageModel = snap.val();
      const authUser = await this.authService.getCurrentAuthUser();
      if (
        (this.messagesObservable.value.length >=
          this.configService.getPagination('messageAmount', false) ||
          this.messagesObservable.value.length >= 30) &&
        message.authorId !== authUser.uid &&
        message.authorId !== 'SYSTEM'
      ) {
        if (
          !this.sideNavigationService.rightOpen ||
          this.sideNavigationService.selectedIndex.value !== 0
        ) {
          this.unreadCount += 1;
        }
        if (!this.sideNavigationService.rightOpen) {
          this.snackBar.openFromComponent(ChatNotificationComponent, {
            data: {
              author: message.authorName,
              content: message.content,
            },
          });
        }
      }
      const messages = this.messagesObservable.value.splice(0);
      messages.push(message);
      this.messagesObservable.next(messages);
      this.loading = false;
    });
  }

  public async loadMoreMessages(): Promise<void> {
    if (!this.queryPointer) {
      return;
    }
    const result = await this.database
      .list<MessageModel>('chat/messages')
      .query.orderByKey()
      .endAt(this.queryPointer)
      .limitToLast(31)
      .once('value');
    const docs = result.val();
    const newMessages: MessageModel[] = [];
    for (const key in docs) {
      if (!docs.hasOwnProperty(key)) {
        continue;
      }
      newMessages.push(docs[key]);
    }
    newMessages.pop();
    if (newMessages.length) {
      this.queryPointer = newMessages[0].id;
    } else {
      this.queryPointer = null;
    }
    const messages = this.messagesObservable.value.splice(0);
    messages.unshift(...newMessages);
    this.messagesObservable.next(messages);
  }

  public async filterByShow(showId: string): Promise<void> {
    setTimeout(() => (this.loading = true));
    this.clearQuery();
    const result = await this.database
      .list<MessageModel>('chat/messages')
      .query.orderByChild('showId')
      .equalTo(showId)
      .once('value');
    const docs = result.val();
    const newMessages: MessageModel[] = [];
    for (const key in docs) {
      if (!docs.hasOwnProperty(key)) {
        continue;
      }
      newMessages.push(docs[key]);
    }
    this.messagesObservable.next(newMessages);
    this.loading = false;
  }

  public async sendMessage(content: string): Promise<void> {
    const user = await this.authService.getCurrentUser();
    const id = this.database.createPushId();
    const message: MessageModel = {
      id,
      content,
      authorName: user.name,
      authorId: user.id,
      authorAvatar: user.avatar,
      backgroundColor: user.backgroundColor,
      color: user.color,
      date: serverTimestamp(),
    };
    if (!isMessageModel(message)) {
      return Promise.reject();
    }
    return this.database.list('chat/messages').set(id, message);
  }

  public async sendReservedMessage(show: ShowModel, seats: SeatModel[]): Promise<void> {
    if (!show || !seats || !seats.length) {
      return;
    }
    const user = await this.authService.getCurrentUser();
    const id = this.database.createPushId();
    const message: MessageModel = {
      id,
      content: `${user.name} hat ${seats.length} ${seats.length > 1 ? 'Plätze' : 'Platz'} für ${
        seats[0].state.info.visitor.name
      } für die Vorstellung am ${new Date(
        show.date.seconds * 1000,
      ).toLocaleDateString()} reserviert.`,
      authorName: 'SYSTEM',
      authorId: 'SYSTEM',
      showId: show.id,
      type: 'success',
      date: serverTimestamp(),
    };
    if (!isMessageModel(message)) {
      return Promise.reject();
    }
    return this.database.list('chat/messages').set(id, message);
  }

  public async sendUnReserveMessage(show: ShowModel, seats: SeatModel[]): Promise<void> {
    if (!show || !seats || !seats.length) {
      return;
    }
    const user = await this.authService.getCurrentUser();
    const id = this.database.createPushId();
    let message: MessageModel;
    if (seats.length === 1) {
      message = {
        id,
        content: `${user.name} hat eine Reservierung (Platz ${seats[0].number}) für ${
          seats[0].state.info.visitor.name
        } für die Vorstellung am ${new Date(
          show.date.seconds * 1000,
        ).toLocaleDateString()} gelöscht.`,
        authorName: 'SYSTEM',
        authorId: 'SYSTEM',
        showId: show.id,
        type: 'warn',
        date: serverTimestamp(),
      };
    } else {
      message = {
        id,
        content: `${user.name} hat alle Reservierungen (${seats.length} Plätze) für ${
          seats[0].state.info.visitor.name
        } für die Vorstellung am ${new Date(
          show.date.seconds * 1000,
        ).toLocaleDateString()} gelöscht.`,
        authorName: 'SYSTEM',
        authorId: 'SYSTEM',
        showId: show.id,
        type: 'warn',
        date: serverTimestamp(),
      };
    }
    if (!isMessageModel(message)) {
      return Promise.reject();
    }
    return this.database.list('chat/messages').set(id, message);
  }

  private clearQuery() {
    this.messagesObservable.next([]);
    this.queryPointer = null;
    if (this.query) {
      this.query.off();
    }
  }

  ngOnDestroy() {
    this.clearQuery();
    this.clearSubscriptions.next(undefined);
    this.clearSubscriptions.complete();
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }
}
