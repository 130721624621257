<div class="container-fluid">
  <div class="row justify-content-end">
    <div class="col-auto pt-3" *ngIf="show && (chatService.messagesObservable.value.length || filterEnabled || chatService.loading)">
      <mat-checkbox (change)="checkboxChange($event)" labelPosition="before">{{ 'SHOW_OVERVIEW.CHAT.ONLY_ACTIVITIES' | translate }}</mat-checkbox>
    </div>
    <div class="col-12">
      <form (ngSubmit)="sendMessage()" [formGroup]="sendMessageForm">
        <mat-form-field appearance="outline" class="w-100">
          <input (keyup)="onKeyUp($event)" matInput formControlName="content" placeholder="{{ 'SHOW_OVERVIEW.CHAT.PLACEHOLDER' | translate }}" />
          <button [disabled]="filterEnabled" matSuffix mat-icon-button type="submit">
            <mat-icon>send</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>
