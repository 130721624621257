<div class="container-fluid message">
  <div
    class="row"
    [ngClass]="{
      'justify-content-end': !isForeignAuthor(),
      'justify-content-start': isForeignAuthor()
    }"
  >
    <div
      [class.col-10]="message.authorId !== 'SYSTEM'"
      [class.col-12]="message.authorId === 'SYSTEM'"
      [class.mat-elevation-z4]="message.authorId !== 'SYSTEM'"
      [style.color]="message?.color"
      [style.background-color]="message?.backgroundColor"
      [ngClass]="getMessageClass()"
    >
      <div *ngIf="message.authorId !== 'SYSTEM'" class="row align-items-center">
        <div class="col">
          <img [src]="message.authorAvatar" />
          <span class="ml-2 font-weight-bold">{{ message.authorName }}</span>
        </div>
        <div class="col-auto">{{ message.date | localizedDate: 'short' }}</div>
      </div>
      <div *ngIf="message.authorId === 'SYSTEM'" class="row justify-content-end">
        <div class="col-auto">{{ message.date | localizedDate: 'short' }}</div>
      </div>
      <div class="row">
        <div class="col">{{ message.content }}</div>
      </div>
    </div>
  </div>
</div>
