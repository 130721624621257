import { has } from '../conditions';
import { SeatType } from '../types/seat.type';
import { isStateInfoModel, StateInfoModel } from './state-info.model';

export interface StateModel {
  value: SeatType;
  sendMail?: boolean; // is required for seats whose visitor is about to be deleted (by settings info to null)
  amount?: number; // is required for seats whose visitor is about to be deleted (by settings info to null)
  info?: StateInfoModel | null;
}

export function isStateModel(object: any): object is StateModel {
  return object &&
    has(object).string('value') &&
    has(object).optional().boolean('sendMail') &&
    has(object).optional().number('amount') &&
    has(object).optional().object('info', () => isStateInfoModel(object.info));
}
