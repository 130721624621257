import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SeatModel } from '../../../../../../shared/models/seat.model';
import { ShowVisitorModel } from '../../../../../../shared/models/show-visitor.model';
import { ShowModel } from '../../../../../../shared/models/show.model';
import { ChatService } from '../../../services/chat.service';
import { SeatService } from '../../../services/seat.service';
import { ShowService } from '../../../services/show.service';
import { SideNavigationService } from '../../../services/side-navigation.service';
import { PAGINATION } from '../../../shared/config/config';
import { DeleteComponent } from '../../delete/delete.component';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit, OnDestroy {
  singleSort: MatSort;
  allSort: MatSort;
  singlePaginator: MatPaginator;
  allPaginator: MatPaginator;

  public currentShowDataSource = new MatTableDataSource<any>([]);
  public allShowsDataSource = new MatTableDataSource<any>([]);
  public currentShowDisplayedColumns: string[] = [
    'name',
    'amount',
    'price',
    'paidAmount',
    'actions',
  ];
  public allShowsDisplayedColumns: string[] = ['name', 'email', 'amount', 'show'];
  public currentShowSearchCtrl = new UntypedFormControl();
  public allShowsSearchCtrl = new UntypedFormControl();
  public currentShow: ShowModel;
  public allShows: ShowModel[];
  public seats: SeatModel[];

  public paginationConfig = PAGINATION;

  private destroyed = new Subject();

  constructor(
    public sideNavigationService: SideNavigationService,
    private seatService: SeatService,
    private showService: ShowService,
    private chatService: ChatService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  @ViewChild('currentShowSort') set currentShowSort(ms: MatSort) {
    this.singleSort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild('allShowsSort') set allShowsSort(ms: MatSort) {
    this.allSort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild('currentShowPaginator') set currentShowPaginator(mp: MatPaginator) {
    this.singlePaginator = mp;
    this.setDataSourceAttributes();
  }

  @ViewChild('allShowsPaginator') set allShowsPaginator(mp: MatPaginator) {
    this.allPaginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.currentShowDataSource.sort = this.singleSort;
    this.allShowsDataSource.sort = this.allSort;
    this.currentShowDataSource.paginator = this.singlePaginator;
    this.allShowsDataSource.paginator = this.allPaginator;

    if (this.singleSort) {
      this.currentShowDataSource.filterPredicate = (data: any, filterPred: string) => {
        if (!data || !data.name || !filterPred) {
          return true;
        }
        return data.name.toLowerCase().indexOf(filterPred.toLowerCase()) !== -1;
      };
    }
    if (this.allSort) {
      this.allShowsDataSource.filterPredicate = (data: any, filterPred: string) => {
        if (!data || !data.name || !filterPred) {
          return true;
        }

        const nameLowercase = data.name.toLowerCase();
        const emailLowercase = (data.email || '').toLowerCase();
        const filterPredLowercase = filterPred.toLowerCase();

        return (
          nameLowercase.includes(filterPredLowercase) ||
          emailLowercase.includes(filterPredLowercase)
        );
      };
      this.allShowsDataSource.sortingDataAccessor = (item, property) => {
        if (property === 'show') {
          return item.show.date.seconds;
        }
        return item[property];
      };
    }
  }

  ngOnInit() {
    combineLatest([
      this.showService.shows.asObservable(),
      this.showService.showVisitors.asObservable(),
    ])
      .pipe(
        tap(([shows, showVisitors]) => {
          this.allShows = shows;
          this.transformShowVisitorData(showVisitors);
        }),
        switchMap(() => this.sideNavigationService.data.asObservable()),
        takeUntil(this.destroyed),
        tap(() => (this.currentShow = null)),
        filter((data) => data && data.seats && data.currentShow),
        map((data) => {
          this.currentShow = this.allShows.find((show) => show.id === data.currentShow);
          return data.seats;
        }),
      )
      .subscribe((seats) => {
        this.seats = seats;
        this.transformData(seats);
      });

    this.currentShowSearchCtrl.valueChanges.pipe(startWith('')).subscribe((value: string) => {
      this.currentShowDataSource.filter = value;
    });

    this.allShowsSearchCtrl.valueChanges.pipe(startWith('')).subscribe((value: string) => {
      this.allShowsDataSource.filter = value;
    });
  }

  ngOnDestroy() {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }

  public selectVisitor(visitorId: string, show?: ShowModel) {
    if (show) {
      this.router.navigateByUrl(`reservations/${show.id}`);
    }
    if (this.sideNavigationService.highlightId.value === visitorId) {
      this.sideNavigationService.highlightId.next(null);
    } else {
      this.sideNavigationService.highlightId.next(visitorId);
    }
  }

  public markAsPaid(element: any) {
    const updateSeats: SeatModel[] = [];
    for (const seat of this.seats) {
      if (!seat.state || !seat.state.info || !seat.state.info.visitor) {
        continue;
      }
      if (seat.state.info.visitor.id === element.id) {
        updateSeats.push(seat);
      }
    }
    this.seatService.updateSeats(this.currentShow, updateSeats, 'PAID');
  }

  public markAsNotPaid(element: any) {
    const updateSeats: SeatModel[] = [];
    for (const seat of this.seats) {
      if (!seat.state || !seat.state.info || !seat.state.info.visitor) {
        continue;
      }
      if (seat.state.info.visitor.id === element.id) {
        updateSeats.push(seat);
      }
    }
    this.seatService.updateSeats(this.currentShow, updateSeats, 'OCCUPIED');
  }

  public cancelAll(element: any) {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: '480px',
      data: {
        title: 'ENTITIES.SHOW.UN_RESERVE.TITLE',
        description: 'ENTITIES.SHOW.UN_RESERVE.DESCRIPTION',
        approveVariable: element.name.trim(),
        toggle: true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) {
          return;
        }
        const updateSeats: SeatModel[] = [];
        for (const seat of this.seats) {
          if (!seat.state || !seat.state.info || !seat.state.info.visitor) {
            continue;
          }
          if (seat.state.info.visitor.id === element.id) {
            updateSeats.push(seat);
            seat.state.sendMail = res.toggle;
            seat.state.amount = 0;
          }
        }
        this.seatService.updateSeats(this.currentShow, updateSeats, 'FREE');
      });
  }

  public getPercentage(): number {
    let totalSeatAmount = 0;
    let occupiedSeatAmount = 0;
    for (const show of this.allShows) {
      totalSeatAmount += show.availableSeatAmount;
      occupiedSeatAmount += show.occupiedSeatAmount;
    }
    return occupiedSeatAmount / totalSeatAmount;
  }

  public selectedIndexChange(index: number) {
    this.sideNavigationService.selectedIndex.next(index);
  }

  private transformData(seats: SeatModel[]) {
    const visitors = [];
    const occupiedSeats = seats.filter((seat) => {
      return seat.state && seat.state.info && seat.state.info.visitor;
    });
    for (const seat of occupiedSeats) {
      const existingVisitor = visitors.find((visitor) => visitor.id === seat.state.info.visitor.id);
      if (!existingVisitor) {
        visitors.push({
          ...seat.state.info.visitor,
          amount: 1,
          paidAmount: seat.state.value === 'PAID' ? 1 : 0,
          show: this.allShows.find((show) => show.id === seat.showId),
        });
      } else {
        existingVisitor.amount += 1;
        if (seat.state.value === 'PAID') {
          existingVisitor.paidAmount += 1;
        }
      }
    }
    this.currentShowDataSource.data = visitors;
  }

  private transformShowVisitorData(shows: ShowVisitorModel[]) {
    const visitors = [];
    for (const show of shows) {
      const existingShow = this.allShows.find((exShow) => exShow.id === show.id);
      if (!existingShow || !show.visitors) {
        continue;
      }
      for (const id in show.visitors) {
        if (!show.visitors.hasOwnProperty(id)) {
          continue;
        }
        if (show.visitors[id].amount > 0) {
          visitors.push({
            ...show.visitors[id].info.visitor,
            show: this.allShows.find((exShow) => exShow.id === show.id),
            amount: show.visitors[id].amount || 0,
            paidAmount: show.visitors[id].paidAmount || 0,
          });
        }
      }
    }
    this.allShowsDataSource.data = visitors;
  }
}
