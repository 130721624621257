import { Injectable, OnDestroy } from '@angular/core';
import {
  AngularFirestore,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { OrderByDirection, serverTimestamp, WhereFilterOp } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ShowVisitorModel } from '../../../../shared/models/show-visitor.model';
import { isShowModel, ShowModel } from '../../../../shared/models/show.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ShowService implements OnDestroy {
  private clearSubscriptions = new Subject();
  private destroyed = new Subject();

  public shows = new BehaviorSubject<ShowModel[]>([]);
  public showVisitors = new BehaviorSubject<ShowVisitorModel[]>([]);

  constructor(
    private firestore: AngularFirestore,
    private firebaseStorage: AngularFireStorage,
    private authService: AuthService,
  ) {
    this.authService.user
      .asObservable()
      .pipe(takeUntil(this.destroyed))
      .subscribe((user) => {
        if (user) {
          this.subscribeShows();
          this.subscribeShowVisitors();
        } else {
          this.clearSubscriptions.next(undefined);
          this.shows.next([]);
          this.showVisitors.next([]);
        }
      });
  }

  public async createShow(show: ShowModel, progress?: Function): Promise<void> {
    const user = await this.authService.getCurrentAuthUser();
    show.id = this.firestore.createId();
    show.seatAmount = 0;
    show.availableSeatAmount = 0;
    show.occupiedSeatAmount = 0;
    show.paidSeatAmount = 0;
    show.createdDate = serverTimestamp();
    show.updatedDate = serverTimestamp();
    show.owner = user.uid;
    if (!isShowModel(show)) {
      return Promise.reject();
    }
    return this.firestore.collection<ShowModel>('shows').doc(show.id).set(show);
  }

  public async updateShow(show: ShowModel, progress?: Function): Promise<void> {
    show.updatedDate = serverTimestamp();
    if (!isShowModel(show)) {
      return Promise.reject();
    }
    return this.firestore.collection<ShowModel>('shows').doc(show.id).set(show);
  }

  public deleteShow(show: ShowModel): Promise<void> {
    return this.firestore.collection<ShowModel>('shows').doc(show.id).delete();
  }

  public getShow(id: string): Observable<ShowModel> {
    return this.firestore
      .collection<ShowModel>('shows')
      .doc<ShowModel>(id)
      .valueChanges()
      .pipe(
        takeUntil(this.destroyed),
        map((show) => {
          show.date = new Date(show.date.seconds * 1000);
          return show;
        }),
      );
  }

  public getAllPaged(
    field: string = 'createdDate',
    orderBy: OrderByDirection = 'asc',
    limit: number = 10,
    startAfter?: QueryDocumentSnapshot<ShowModel>,
    searchValue?: any,
    opStr?: WhereFilterOp,
  ): Observable<QueryDocumentSnapshot<ShowModel>[]> {
    return this.firestore
      .collection<ShowModel>('shows', (ref) => {
        let query: Query;
        if (['boolean', 'number'].includes(typeof searchValue) || searchValue) {
          query = ref.where(field, opStr, searchValue);
        } else {
          query = ref.limit(limit);
          query = query.orderBy(field, orderBy);
          if (startAfter) {
            query = query.startAfter(startAfter);
          }
        }
        return query;
      })
      .get()
      .pipe(map((res: QuerySnapshot<ShowModel>) => res.docs));
  }

  private subscribeShows() {
    return this.firestore
      .collection<ShowModel>('shows', (ref) => ref.orderBy('date'))
      .valueChanges()
      .pipe(takeUntil(this.clearSubscriptions))
      .subscribe((shows) => this.shows.next(shows));
  }

  private subscribeShowVisitors() {
    return this.firestore
      .collection<ShowVisitorModel>('showVisitors')
      .valueChanges()
      .pipe(takeUntil(this.clearSubscriptions))
      .subscribe((showVisitors) => this.showVisitors.next(showVisitors));
  }

  ngOnDestroy() {
    this.clearSubscriptions.next(undefined);
    this.clearSubscriptions.complete();
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }
}
