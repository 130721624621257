import { Component, Input, OnInit } from '@angular/core';
import { MessageModel } from '../../../../../../shared/models/message.model';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  @Input() message: MessageModel;
  @Input() nextMessage: boolean;
  @Input() prevMessage: boolean;

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  isForeignAuthor(): boolean {
    return this.authService.user.value.uid !== this.message.authorId;
  }

  getMessageClass(): string {
    if (this.message.authorId === 'SYSTEM') {
      return `system ${this.message.type}`;
    }
    if (this.nextMessage && this.prevMessage) {
      return this.isForeignAuthor() ? 'foreign middle' : 'own middle';
    }
    if (this.nextMessage) {
      return this.isForeignAuthor() ? 'foreign top' : 'own top';
    }
    if (this.prevMessage) {
      return this.isForeignAuthor() ? 'foreign bottom' : 'own bottom';
    }
    return this.isForeignAuthor() ? 'foreign normal' : 'own normal';
  }
}
