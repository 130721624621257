import { has } from '../conditions';

export interface ShowModel {
  id: string;
  owner: string;
  createdDate: any;
  updatedDate: any;
  date: any;
  costs: number;
  seatAmount: number;
  availableSeatAmount: number;
  occupiedSeatAmount: number;
  paidSeatAmount: number;
  note?: string;
}

export function isShowModel(object: any): object is ShowModel {
  return object &&
    has(object).string('id') &&
    has(object).string('owner') &&
    has(object).object('createdDate') &&
    has(object).object('updatedDate') &&
    has(object).object('date') &&
    has(object).number('costs') &&
    has(object).number('seatAmount') &&
    has(object).number('availableSeatAmount') &&
    has(object).number('occupiedSeatAmount') &&
    has(object).number('paidSeatAmount') &&
    has(object).optional().string('note');
}
