import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutoScrollDirective } from '../../directives/auto-scroll.directive';
import { SharedModule } from '../../shared/shared.module';
import { ChatContentComponent } from './chat-content/chat-content.component';
import { ChatInputComponent } from './chat-input/chat-input.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { ChatNotificationComponent } from './chat-notification/chat-notification.component';
import { ChatComponent } from './chat/chat.component';

@NgModule({
  declarations: [
    ChatComponent,
    ChatInputComponent,
    ChatMessageComponent,
    ChatNotificationComponent,
    ChatContentComponent,
    AutoScrollDirective,
  ],
  imports: [
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    InfiniteScrollModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    ChatComponent,
    ChatInputComponent,
    ChatMessageComponent,
    ChatNotificationComponent,
    ChatContentComponent,
  ],
})
export class ChatModule {}
